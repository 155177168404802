import { CommonEvent } from '../models/event/CommonEvent'
//import { EventCreate } from '../models/event/EventCreate'
import { EventPromotion } from '../models/event/EventPromotion'
import { fetchApi } from './fetch'

const URL_GET_EVENT = 'Event/GetEvent'
const URL_GET_EVENTS = 'Event/get-events'
const URL_SAVE = 'Event/SaveSaleOfferEvent'
const URL_VIDEO_PATCH = 'Event/VideoEvent'

const fetchGetEvent = (): Promise<EventPromotion> => fetchApi(URL_GET_EVENT, 'GET')
const fetchGetEvents = (): Promise<CommonEvent[]> => fetchApi(URL_GET_EVENTS, 'GET')
const fetchPostEvent = (data: any) => fetchApi(URL_SAVE, 'POST', data)
const fetchUpdateVideoEvent = (id: any, data: any) => fetchApi(`${URL_VIDEO_PATCH}/${id}`, 'PATCH', data)

export const eventApi = {
  fetchGetEvent,
  fetchGetEvents,
  fetchPostEvent,
  fetchUpdateVideoEvent,
}
