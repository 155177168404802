import { FC } from 'react'
import { Link } from 'react-router-dom'

const ComingSoon: FC = () => {
  return (
    <>
      <li>
        <Link
          to={{
            pathname: '/new-frontera',
          }}
        >
          New Frontera
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/new-grandland',
          }}
        >
          New Grandland
        </Link>
      </li>
    </>
  )
}

export default ComingSoon
