import * as React from 'react'
import { Switch, Route } from 'react-router'
import Layout from './components/layout/Layout'
import { FabllbackProvider } from './components/providers/FallbackProvider'
import * as utils from './utils'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Error from './pages/error/Error'
import { emailApi } from './api/emailing'

const Stock = React.lazy(() => {
  return import('./components/stock/Stock')
})
const StockLocator = React.lazy(() => {
  return import('./components/stockLocator/StockLocator')
})
const Main = React.lazy(() => {
  return import('./components/main/Main')
})
// const ValueCar = React.lazy(() => {
//   return import('./components/valueCar/ValueCar')
// })
const SellCar = React.lazy(() => {
  return import('./pages/sellCar/SellCar')
})

// const StockDetails = React.lazy(() => {
//   return import('./components/stock/StockDetails')
// })
const StockDetails = React.lazy(() => {
  return import('./pages/stockDetails/StockDetails')
})
const NewCars = React.lazy(() => {
  return import('./components/newCars/NewCars')
})
// const NewCarDetails = React.lazy(() => {
//   return import('./components/newCars/NewCarDetails')
// })

// const NewCarDetailsNew = React.lazy(() => {
//   return import('./pages/newCarDetails/NewCarDetails')
// })
const NewCarOffer = React.lazy(() => {
  return import('./pages/newCarOffer/NewCarOffer')
})
const ElectricOffer = React.lazy(() => {
  return import('./pages/electricOffer/ElectricOffer')
})
const LatestOffers = React.lazy(() => {
  return import('./pages/latestOffers/Index')
})
// const ModelCatalog = React.lazy(() => {
//   return import('./components/newCars/modelCatalog/ModelCatalog')
// })
// const ModelTrim = React.lazy(() => {
//   return import('./components/newCars/modelTrim/ModelTrim')
// })
const TestDrive = React.lazy(() => {
  return import('./views/testDrive/TestDrive')
})
// const ContactUs = React.lazy(() => {
//   return import('./pages/contactUs/ContactUs')
// })
const WinterService = React.lazy(() => {
  return import('./components/serviceSpecialPage/WinterService')
})
const WinterServiceSpacial = React.lazy(() => {
  return import('./components/serviceWinter/WinterServiceSpacial')
})
const CarReservation = React.lazy(() => {
  return import('./components/carReservation/CarReservation')
})
const ServiceBooking = React.lazy(() => {
  return import('./components/serviceBooking/ServiceBooking')
})
const ThankYou = React.lazy(() => {
  return import('./components/common/lp/ThankYou')
})
const LpPartRequest = React.lazy(() => {
  return import('./components/common/lp/PartRequest')
})
const PartRequest = React.lazy(() => {
  return import('./components/partRequest/PartRequest')
})
const PaymentResult = React.lazy(() => {
  return import('./components/payment/PaymentResult')
})
const PrivacyPolicy = React.lazy(() => {
  return import('./components/privacyPolicy/PrivacyPolicy')
})
const Refund = React.lazy(() => {
  return import('./components/refund/Refund')
})
const TermsConditions = React.lazy(() => {
  return import('./components/termsConditions/TermsConditions')
})
const ReservationPolicy = React.lazy(() => {
  return import('./components/reservationPolicy/ReservationPolicy')
})
const LpTestDrive = React.lazy(() => {
  return import('./components/common/lp/TestDrive')
})
const LpServiceBooking = React.lazy(() => {
  return import('./components/common/lp/ServiceBooking')
})
const LpSalesOffer = React.lazy(() => {
  return import('./components/common/lp/SalesOffer')
})
const LpRefund = React.lazy(() => {
  return import('./components/common/lp/Refund')
})
const LpError = React.lazy(() => {
  return import('./components/common/lp/Error')
})
const MarketingConsent = React.lazy(() => {
  return import('./components/marketingConsent/MarketingConsent')
})
const About = React.lazy(() => {
  return import('./components/about/About')
})
const BookAppointment = React.lazy(() => {
  return import('./components/bookAppointment/BookAppointment')
})
const LpBookAppointment = React.lazy(() => {
  return import('./components/common/lp/BookAppointment')
})
const ArrangeAppointment = React.lazy(() => {
  return import('./components/arrangeAppointment/ArrangeAppointment')
})
const ArrangeHandover = React.lazy(() => {
  return import('./components/arrangeHandover/ArrangeHandover')
})
const QuoteRequest = React.lazy(() => {
  return import('./components/quoteRequest/QuoteRequest')
})
const VideoAppointment = React.lazy(() => {
  return import('./components/video/videoAppointment/VideoAppointment')
})
//import VideoArrange  = React.lazy(() => { return import("./components/video/videoArrangeю111/VideoArrange") });
const VideoOffer = React.lazy(() => {
  return import('./components/video/offer/Offer')
})
const Event = React.lazy(() => {
  return import('./components/event/Event')
})
// const SalesOffer = React.lazy(() => {
//   return import('./components/salesOffer/SalesOffer')
// })
const NotFound = React.lazy(() => {
  return import('./components/common/lp/NotFound')
})
const LpHandoverCollect = React.lazy(() => {
  return import('./components/common/lp/HandoverCollect')
})
const AppraisalPhotos = React.lazy(() => {
  return import('./components/appraisal/AppraisalPhotos')
})
const LpServicePlan = React.lazy(() => {
  return import('./components/common/lp/ServicePlan')
})
const ServicePlan = React.lazy(() => {
  return import('./components/servicePlan/ServicePlan')
})
//import PartOrder  = React.lazy(() => { return import("./components/partOrder/PartOrder") });
//import PartOrderDelivery  = React.lazy(() => { return import("./components/partOrder/PartOrderDelivery") });
// const Valuation = React.lazy(() => {
//   return import('./components/valuation/Valuation')
// })
const Unsubscribe = React.lazy(() => {
  return import('./components/unsubscribe/Unsubscribe')
})
const LpUnsubscribe = React.lazy(() => {
  return import('./components/common/lp/Unsubscribe')
})
const LpQuoteRequest = React.lazy(() => {
  return import('./components/common/lp/QuoteRequest')
})
const PaymentResultCyber = React.lazy(() => {
  return import('./components/paymentCyber/PaymentResultCyber')
})
const LpOnlinePayment = React.lazy(() => {
  return import('./components/common/lp/OnlinePayment')
})
const LpReserveOnline = React.lazy(() => {
  return import('./components/common/lp/ReserveOnline')
})
const LpBuildDeal = React.lazy(() => {
  return import('./components/common/lp/BuildDeal')
})
const LpPartOrder = React.lazy(() => {
  return import('./components/common/lp/PartOrder')
})
//import DepositPayment from "./components/depositPayment/DepositPayment") });
const OnlinePayment = React.lazy(() => {
  return import('./components/onlinePayment/OnlinePayment')
})
const DepositPayment = React.lazy(() => {
  return import('./components/depositPayment/DepositPayment')
})
const Feedback = React.lazy(() => {
  return import('./components/feedback/Feedback')
})
//const ServicePlan = React.lazy(() => { return import("./components/servicePlan/ServicePlan") });
const PartOrder = React.lazy(() => {
  return import('./components/partOrder/PartOrder')
})
const BuildDeal = React.lazy(() => {
  return import('./components/buildDeal/BuildDeal')
})
// const ApplyFinance = React.lazy(() => {
//   return import('./components/applyFinance/ApplyFinance')
// })
const ApplyFinance = React.lazy(() => {
  return import('./pages/applyFinance/ApplyFinance')
})
const Motability = React.lazy(() => {
  return import('./components/motability/Motability')
})
const DriveNowPayLater = React.lazy(() => {
  return import('./components/driveNowPayLater/DriveNowPayLater')
})
const FreeRac = React.lazy(() => {
  return import('./components/freeRac/FreeRac')
})
const SellCarAppointment = React.lazy(() => {
  return import('./components/sellCarAppointment/SellCarAppointment')
})
const LpPaymentStatus = React.lazy(() => {
  return import('./components/common/lp/PaymentStatus')
})
const ElectricProfile = React.lazy(() => {
  return import('./pages/electricProfile/ElectricProfile')
})
const ElectricVehicle = React.lazy(() => {
  return import('./pages/electricProfile/ElectricVehicle')
})
const ElectricBuildDeal = React.lazy(() => {
  return import('./pages/electricProfile/BuildDeal')
})
const OfferReservation = React.lazy(() => {
  return import('./pages/offerReservation/OfferReservation')
})
const OfferReservationStock = React.lazy(() => {
  return import('./pages/offerReservation/OfferReservationStock')
})
const OfferReservationLink = React.lazy(() => {
  return import('./pages/offerReservation/OfferReservationLink')
})
const ContactsCatalog = React.lazy(() => {
  return import('./pages/contactsCatalog/ContactsCatalog')
})
const ContactsCatalogDetails = React.lazy(() => {
  return import('./pages/contactsCatalog/ContactsCatalogDetails')
})
const OfferPage = React.lazy(() => {
  return import('./pages/offerPage/OfferPage')
})
const EventPreview = React.lazy(() => {
  return import('./pages/eventPreview/EventPreview')
})
const ComplaintsProcess = React.lazy(() => {
  return import('./pages/complaintsProcess/ComplaintsProcess')
})
const FinanceExplained = React.lazy(() => {
  return import('./pages/financeExplained/FinanceExplained')
})
const ElectricVehicleRange = React.lazy(() => {
  return import('./pages/electricVehicleRange/ElectricVehicleRange')
})
const ElectricCharging = React.lazy(() => {
  return import('./pages/electricCharging/ElectricCharging')
})
const ElectricServicing = React.lazy(() => {
  return import('./pages/electricServicing/ElectricServicing')
})
const ElectricCarScheme = React.lazy(() => {
  return import('./pages/electricCarScheme/ElectricCarScheme')
})
const NewCarsMotability = React.lazy(() => {
  return import('./pages/newCarsMotability/NewCarsMotability')
})
const NewCarMotability = React.lazy(() => {
  return import('./pages/newCarMotability/NewCarMotability')
})
const Enquiry = React.lazy(() => {
  return import('./pages/enquiry/Enquiry')
})
const LpEnquiry = React.lazy(() => {
  return import('./components/common/lp/Enquiry')
})
const VehicleEnquiry = React.lazy(() => {
  return import('./pages/vehicleEnquiry/VehicleEnquiry')
})
const NewCorsa = React.lazy(() => {
  return import('./pages/newCorsa/NewCorsa')
})
const MokkaGriffin = React.lazy(() => {
  return import('./pages/mokkaGriffin/MokkaGriffin')
})
const AstraGriffin = React.lazy(() => {
  return import('./pages/astraGriffin/Index')
})
const MotabilityAdaptations = React.lazy(() => {
  return import('./pages/motabilityAdaptations/Index')
})
const NewFrontera = React.lazy(() => {
  return import('./pages/newFrontera/NewFrontera')
})
const YesCorsa = React.lazy(() => {
  return import('./pages/yesCorsa/YesCorsa')
})
const CookiePolicy = React.lazy(() => {
  return import('./pages/cookiePolicy/CookiePolicy')
})
const TrimLevel = React.lazy(() => {
  return import('./pages/trimLevel/TrimLevel')
})

const OfferOverview = React.lazy(() => {
  return import('./pages/offerOverview/OfferOverview')
})

const TrimLevelCatalog = React.lazy(() => {
  return import('./pages/trimLevelCatalog/TrimLevelCatalog')
})

const PlugAndGo = React.lazy(() => {
  return import('./pages/plugAndGo/PlugAndGo')
})

const IncidentForm = React.lazy(() => {
  return import('./pages/incidentForm/IncidentForm')
})

const IncidentFormLp = React.lazy(() => {
  return import('./pages/incidentForm/IncidentFormLp')
})

const PartnersProgramm = React.lazy(() => {
  return import('./pages/partnersProgramm/PartnersProgramm')
})

const ElectricCarMotability = React.lazy(() => {
  return import('./pages/electricCarMotability/ElectricCarMotability')
})

const NewCorsaHybrid = React.lazy(() => {
  return import('./pages/newCorsaHybrid/NewCorsaHybrid')
})

const NewGrandlandHybrid = React.lazy(() => {
  return import('./pages/newGrandlandHybrid/NewGrandlandHybrid')
})

const NewGrandland = React.lazy(() => {
  return import('./pages/newGrandland/NewGrandland')
})

const StockNew = React.lazy(() => {
  return import('./pages/stockNew/StockNew')
})

const StockNewDetails = React.lazy(() => {
  return import('./pages/stockNewDetails/Index')
})

const URL = 'NewOffers/getCarModels'

const loadData = async (setCarModels: any) => {
  utils.carModels['1'] = await utils.api.fetchData(`${URL}?varClassId=1`)
  utils.carModels['2'] = await utils.api.fetchData(`${URL}?varClassId=2`)
  utils.carModels['0'] = [...utils.carModels['1'], ...utils.carModels['2']]
  setCarModels(utils.carModels)
}

const App = () => {
  const [carModels, setCarModels] = useState<any>()

  React.useEffect(() => {
    loadData(setCarModels)
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  })

  const logErrorToService = (error: Error, info: any) => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    const cssChunkFailedMessage = /Loading CSS chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload()
    } else if (error?.message && cssChunkFailedMessage.test(error.message)) {
      window.location.reload()
    } else {
      try {
        emailApi.fetchPostNotification({
          subject: 'Client Error',
          to: 's.devyashov@advancevauxhall.co.uk',
          body: `${error.message} </br> ${info?.componentStack} </br> ${window.location.href}`,
        })
      } catch (error) {}
    }
  }

  return (
    <>
      <ToastContainer />
      <Switch>
        <Route exact path={'/payment-result-cyber'} render={() => <PaymentResultCyber isFrame={true} />} />

        <Layout>
          <ErrorBoundary fallback={<Error />} onError={logErrorToService}>
            <FabllbackProvider>
              <Switch>
                <Route exact path={'/service-plan/:quotaId?'} render={() => <ServicePlan />} />
                <Route exact path={'/lp/service-plan/thank-you'} render={() => <LpServicePlan />} />
                <Route exact path={'/payment-finish'} render={() => <PaymentResult isFrame={false} />} />

                <Route exact path={'/stock-car'} render={() => <Stock />} />
                <Route exact path={'/stock-car/:id'} render={() => <StockDetails />} />
                <Route exact path={'/new-vehicles-stock'} render={() => <StockNew />} />
                <Route exact path={'/new-vehicles-stock/:id'} render={() => <StockNewDetails />} />
                <Route exact path={'/value-car/:valueCarId?'} render={() => <SellCar />} />
                <Route exact path={'/value-car-offer/:valueCarId'} render={() => <SellCar />} />

                <Route path={'/service-booking'} render={() => <ServiceBooking />} />
                <Route exact path={'/lp/service-booking/thank-you'} render={() => <LpServiceBooking />} />
                <Route exact path={'/test-drive'} render={() => <TestDrive />} />
                <Route exact path={'/test-drive/thank-you'} render={() => <LpTestDrive />} />
                <Route path={'/thank-you'} render={() => <ThankYou />} />
                <Route path={'/error'} render={() => <LpError />} />
                <Route exact path={'/book-appointment/:valueCarId?'} render={() => <BookAppointment />} />
                <Route exact path={'/lp/book-appointment/thank-you'} render={() => <LpBookAppointment />} />
                <Route exact path={'/build-deal/variant/:variantId'} render={() => <BuildDeal />} />
                <Route exact path={'/build-deal/stock/:stockId'} render={() => <BuildDeal />} />
                <Route exact path={'/build-deal/:dealId'} render={() => <BuildDeal />} />
                <Route exact path={'/apply-finance/:dealId/:vehicleId?'} render={() => <ApplyFinance />} />
                <Route exact path={'/deposit-payment/:dealId/:vehicleId?'} render={() => <DepositPayment />} />
                <Route exact path={'/new-cars-offers'} render={() => <NewCars varClassId={1} />} />
                <Route exact path={'/new-vans-offers'} render={() => <NewCars varClassId={2} />} />
                <Route exact path={'/new-cars-offers/:customModel'} render={() => <TrimLevel varClassId={1} />} />
                <Route exact path={'/new-vans-offers/:customModel'} render={() => <TrimLevel varClassId={2} />} />
                <Route exact path={'/contact-us'} render={() => <ContactsCatalog />} />
                <Route exact path={'/contact-us/:branchId'} render={() => <ContactsCatalogDetails />} />
                <Route exact path={'/privacy-policy'} render={() => <PrivacyPolicy />} />
                <Route exact path={'/terms-conditions'} render={() => <TermsConditions />} />
                <Route exact path={'/reservation-policy'} render={() => <ReservationPolicy />} />
                <Route exact path={'/marketing-consent'} render={() => <MarketingConsent />} />
                <Route exact path={'/about-us'} render={() => <About />} />
                <Route exact path={'/part-request'} render={() => <PartRequest />} />
                <Route exact path={'/part-request/thank-you'} render={() => <LpPartRequest />} />
                <Route exact path={'/refund/:id?'} render={() => <Refund />} />
                <Route exact path={'/lp/refund/thank-you'} render={() => <LpRefund />} />
                <Route
                  exact
                  path={'/new-cars-offers/:customModel/:catalog'}
                  render={() => <TrimLevelCatalog varClassId={1} />}
                />
                <Route
                  exact
                  path={'/new-vans-offers/:customModel/:catalog'}
                  render={() => <TrimLevelCatalog varClassId={2} />}
                />
                <Route
                  exact
                  path={'/new-cars-offers/:customModel/:customModelTrim/:carId'}
                  render={() => <NewCarOffer varClassId={1} />}
                />
                <Route
                  exact
                  path={'/new-vans-offers/:customModel/:customModelTrim/:carId'}
                  render={() => <NewCarOffer varClassId={2} />}
                />

                <Route exact path={'/online-payment/:paymentId?'} render={() => <OnlinePayment />} />
                <Route exact path={'/lp/online-payment/thank-you'} render={() => <LpOnlinePayment />} />
                <Route exact path={'/lp/payment-status'} render={() => <LpPaymentStatus />} />

                <Route exact path={'/'} render={() => <Main />} />
                <Route exact path={'/quote-request/:leadId?'} render={() => <QuoteRequest />} />
                <Route exact path={'/lp/quote-request/thank-you'} render={() => <LpQuoteRequest />} />
                <Route exact path={'/part-order-enquiry/:orderId'} render={() => <PartOrder />} />
                {/*	<Route exact path={"/part-order-delivery/:orderId"} render={() => (<PartOrderDelivery />)} />*/}
                <Route exact path={'/arrange-handover/:dealId/:vehicleId?'} render={() => <ArrangeHandover />} />
                <Route exact path={'/lp/arrange-handover/thank-you'} render={() => <LpHandoverCollect />} />
                <Route exact path={'/arrange-appointment'} render={() => <ArrangeAppointment />} />
                {/* <Route exact path={'/sales-offer'} render={() => <SalesOffer />} /> */}
                <Route exact path={'/sales-offer/thank-you'} render={() => <LpSalesOffer />} />
                <Route exact path={'/event'} render={() => <Event />} />
                <Route exact path={'/video-appointment'} render={() => <VideoAppointment />} />
                {/*	<Route exact path={"/VideoOfferArrange"} render={() => (<VideoArrange />)} />*/}
                <Route exact path={'/events-offer/:code/:videoId'} render={() => <VideoOffer />} />
                <Route exact path={'/appraisal-photos/:id'} render={() => <AppraisalPhotos />} />
                {/* <Route exact path={"/value-car-offer/:id"} render={() => (<Valuation />)} /> */}
                <Route exact path={'/feedback'} render={() => <Feedback />} />
                <Route exact path={'/unsubscribe'} render={() => <Unsubscribe />} />
                <Route exact path={'/lp/unsubscribe'} render={() => <LpUnsubscribe />} />
                <Route exact path={'/lp/reserve-online/thank-you'} render={() => <LpReserveOnline />} />
                <Route exact path={'/lp/build-deal/thank-you'} render={() => <LpBuildDeal />} />
                <Route exact path={'/lp/part-order/thank-you'} render={() => <LpPartOrder />} />
                <Route exact path={'/vehicle-health-check'} render={() => <WinterService />} />
                <Route exact path={'/service-special-offer'} render={() => <WinterServiceSpacial />} />
                <Route exact path={'/rac-breakdown-cover'} render={() => <FreeRac />} />
                <Route exact path={'/motability'} render={() => <Motability />} />
                <Route exact path={'/drive-now-pay-later'} render={() => <DriveNowPayLater />} />
                <Route exact path={'/new-car-order/:id?'} render={() => <CarReservation />} />
                <Route exact path={'/stock-locator'} render={() => <StockLocator />} />
                <Route exact path={'/sell-car-appointment/:valueCarId'} render={() => <SellCarAppointment />} />

                <Route exact path={'/electric-cars'} render={() => <ElectricProfile />} />
                <Route exact path={'/electric-cars/:customModel'} render={() => <ElectricVehicle />} />
                <Route exact path={'/electric-cars/build-deal/:customModel'} render={() => <ElectricBuildDeal />} />

                <Route exact path={'/offer-reservation'} render={() => <OfferReservation />} />
                <Route exact path={'/offer-reservation/:stockId'} render={() => <OfferReservationStock />} />
                <Route exact path={'/offer-reservation/:dealId/:vehicleId'} render={() => <OfferReservationLink />} />

                <Route exact path={'/sales-offer/:symbolCode'} render={() => <OfferPage />} />

                <Route exact path={'/event-preview/:eventId'} render={() => <EventPreview />} />

                <Route exact path={'/complaints-process'} render={() => <ComplaintsProcess />} />

                <Route exact path={'/finance-explained'} render={() => <FinanceExplained />} />

                <Route exact path={'/electric-vehicle-range'} render={() => <ElectricVehicleRange />} />

                <Route exact path={'/electric-charging'} render={() => <ElectricCharging />} />

                <Route exact path={'/electric-servicing'} render={() => <ElectricServicing />} />

                <Route exact path={'/plug-and-go'} render={() => <PlugAndGo />} />

                <Route exact path={'/motability-car-scheme'} render={() => <ElectricCarScheme />} />

                <Route exact path={'/new-car-motability'} render={() => <NewCarsMotability />} />

                <Route exact path={'/new-car-motability/:id'} render={() => <NewCarMotability />} />

                <Route exact path={'/motability-enquiry/:id?'} render={() => <Enquiry />} />
                <Route exact path={'/lp/motability-enquiry/thank-you'} render={() => <LpEnquiry />} />

                <Route exact path={'/vehicle-enquiry/:id?'} render={() => <VehicleEnquiry />} />
                <Route exact path={'/lp/vehicle-enquiry/thank-you'} render={() => <LpEnquiry />} />

                <Route exact path={'/new-corsa'} render={() => <NewCorsa />} />

                <Route exact path={'/yes-corsa'} render={() => <YesCorsa />} />

                <Route exact path={'/mokka-griffin'} render={() => <MokkaGriffin />} />

                <Route exact path={'/new-frontera'} render={() => <NewFrontera />} />

                <Route exact path={'/cookie-policy'} render={() => <CookiePolicy />} />

                <Route exact path={'/offer-overview/:dealId'} render={() => <OfferOverview />} />

                <Route exact path={'/partners-program'} render={() => <PartnersProgramm />} />

                <Route exact path={'/incident-form/:id'} render={() => <IncidentForm />} />

                <Route exact path={'/incident-form-thank-you'} render={() => <IncidentFormLp />} />

                <Route exact path={'/electric-car-offer'} render={() => <ElectricOffer />} />

                <Route exact path={'/electric-car-motability'} render={() => <ElectricCarMotability />} />

                <Route exact path={'/corsa-hybrid'} render={() => <NewCorsaHybrid />} />

                <Route exact path={'/grandland-hybrid'} render={() => <NewGrandlandHybrid />} />

                <Route exact path={'/new-grandland'} render={() => <NewGrandland />} />

                <Route exact path={'/latest-offers'} render={() => <LatestOffers />} />

                <Route exact path={'/astra-griffin'} render={() => <AstraGriffin />} />

                <Route exact path={'/motability-adaptations'} render={() => <MotabilityAdaptations />} />

                <Route path='*' render={() => <NotFound />} />
              </Switch>
            </FabllbackProvider>
          </ErrorBoundary>
        </Layout>
      </Switch>
    </>
  )
}

export default App
