import { FC, useEffect, useState } from 'react'
import imgMobile from '../../../assets/images/eventCaption/74-plates-Corsa-head-mobile.avif'
import imgDesk from '../../../assets/images/eventCaption/74-plates-Corsa-head.avif'
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom'

const EventCaption: FC = () => {
  const history = useHistory()

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 200
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > heightToHideFrom) {
      isVisible && setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  return (
    <>
      {isVisible && (
        <div className={styles.eventCaption}>
          <div className={styles.eventWrapper}>
            <div
              className={styles.banner}
              onClick={(e) => {
                e.preventDefault()
                history.push('/latest-offers')
              }}
            >
              <picture className={styles.pic}>
                <source media='(max-width: 576px)' srcSet={imgMobile} />
                <img className={styles.img} src={imgDesk} alt='Brand New Vauxhall Electric Cars Offer' />
              </picture>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EventCaption
