/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LeadService {

  /**
   * @param id 
   * @param priority 
   * @returns any Success
   * @throws ApiError
   */
  public static postLeadsNotificationsNew(
id: number,
priority: number = 1,
): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/leads/{id}/notifications/new',
      path: {
        'id': id,
      },
      query: {
        'priority': priority,
      },
    });
  }

}
