//import moment from 'moment'
import { FC, memo, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
// import { UserEnvironment } from "../../models/userEnvironment/UserEnvironment";
// import { EnvironmentContext } from "../providers/EnvironmentProvider";
//import { eventApi } from '../../api/events'
//import { EventPromotion } from '../../models/event/EventPromotion'
import './Banner.scss'
//import HeaderClock from './HeaderClock'
import { CommonEvent } from '../../models/event/CommonEvent'
//import { UserEnvironment } from '../../models/userEnvironment/UserEnvironment'
import { EnvironmentContext } from '../providers/EnvironmentProvider'

const Banner: FC = () => {
  const { env } = useContext<any>(EnvironmentContext)
  const history = useHistory()
  const [showBanner, setShowBanner] = useState<boolean | undefined>(false)
  const [isBannerHidden, setIsBannerHidden] = useState<boolean | undefined>(false)
  const location = useLocation()

  const [data, setData] = useState<CommonEvent>()

  const handleClick = (symbolCode: string) => {
    history.push(`/sales-offer/${symbolCode}`)
  }

  const handleClose = () => {
    setIsBannerHidden(true)
  }

  // useEffect(() => {
  //   eventApi.fetchGetEvent().then((response) => {
  //     if (response) {
  //       setData(response)
  //     }
  //   })
  // }, [])

  useEffect(() => {
    if (env.events) {
      let longBannerEvent = env.events.filter(
        (e: CommonEvent) => e.isLongFloatingBannerActive === true && e.isEventActive === true,
      )[0]
      setData(longBannerEvent)
    }
  }, [env])

  useEffect(() => {
    if (data) {
      if (
        location.pathname == '/event' ||
        location.pathname.includes('/offer-page') ||
        location.pathname.includes('/sales-offer') ||
        location.pathname == '/sales-offer/thank-you'
      ) {
        setShowBanner(false)
      } else {
        if (!showBanner) setShowBanner(true)
      }
    }
  }, [data, location])

  // const minDate = moment(data?.minDate, 'YYYY-MM-DDTHH:mm:ss')
  // const maxDate = moment(data?.maxDate, 'YYYY-MM-DDTHH:mm:ss')
  // //const maxDate = moment();
  // const dateDiffInMs = maxDate.diff(minDate)
  // const nowInMs = minDate.valueOf()
  // const targetDate = nowInMs + dateDiffInMs

  // return <>
  //     {!isBannerHidden && showBanner && data && <div className="banner">
  //         <div className="banner__container">
  //             <div className="banner__image" onClick={handleClick} style={{ cursor: "pointer" }}>
  //                 <picture>
  //                     <source media="(max-width: 767px)" srcSet={data.mobileHeaderBanner} />
  //                     <img src={data.desctopHeaderBanner} alt="" />
  //                 </picture>
  //             </div>
  //         </div>

  //         <div className="banner__close" onClick={handleClose}>
  //             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  //                 <path d="M0 0h24v24H0z" fill="none"></path>
  //                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
  //             </svg>
  //         </div>
  //     </div>}
  // </>
  return (
    <>
      {!isBannerHidden && showBanner && data && (
        <div className='banner'>
          <div
            className='banner__container'
            onClick={() => {
              handleClick(data.symbolCode)
            }}
            style={{ cursor: 'pointer' }}
          >
            <div className='banner__image'>
              <picture>
                <source media='(max-width: 767px)' srcSet={data.mobileFloatingBanner} />
                <img src={data.longFloatingBanner} alt='banner' />
              </picture>
            </div>
          </div>
          <div className='banner__close' onClick={handleClose}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path d='M0 0h24v24H0z' fill='none' />
              <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
            </svg>
          </div>
        </div>
        // <div className='banner'>
        //   <div className='headerClock'>
        //     <div
        //       className='headerClock__banner'
        //       onClick={() => {
        //         handleClick(data.symbolCode)
        //       }}
        //       style={{ cursor: 'pointer' }}
        //     >
        //       {/* <HeaderClock targetDate={targetDate} /> */}
        //       <picture>
        //         <source media='(max-width: 900px)' srcSet={data.mobileBanner} />
        //         <img src={data.longFloatingBanner} alt='banner' />
        //       </picture>
        //     </div>
        //   </div>
        //   <div className='banner__close' onClick={handleClose}>
        //     <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        //       <path d='M0 0h24v24H0z' fill='none'></path>
        //       <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'></path>
        //     </svg>
        //   </div>
        // </div>
      )}
    </>
  )
}

export default memo(Banner)
