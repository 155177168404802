import { Link } from 'react-router-dom'

const App = ({ varClassId }: any) => {
  let cars = [
    {
      id: 19,
      modelName: 'CORSA',
      displayName: 'Corsa',
      urlName: 'corsa',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/57686dce-35c6-46a5-932b-6ecb847139f4',
      description: 'All-New Corsa. Up to £1,500 towards your deposit',
      lastMod: '2023-04-06T19:21:20.3595637',
    },
    // {
    //   id: 20,
    //   modelName: 'CORSA-E',
    //   displayName: 'Corsa Electric',
    //   urlName: 'corsa-electric',
    //   photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/3384ae44-3499-4792-8aeb-07e4cb05ae5e',
    //   description: 'Plug & Go with Vauxhall. 8 Years Battery Warranty and Roadside Assistance',
    //   lastMod: '2023-04-06T18:42:32.8351076',
    // },
    {
      id: 27,
      modelName: 'MOKKA',
      displayName: 'Mokka',
      urlName: 'mokka',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/5df97f62-d6ba-401e-866a-5ec43d2675bf',
      description: 'All-New Mokka. Up to £1,250 towards your deposit',
      lastMod: '2023-01-30T21:34:15.7076967',
    },
    {
      id: 28,
      modelName: 'MOKKA-E',
      displayName: 'Mokka Electric',
      urlName: 'mokka-electric',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/5fc2da20-1aad-41b8-a662-72403556dfcc',
      description: 'Plug & Go with Vauxhall. 8 Years Battery Warranty and Roadside Assistance',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 24,
      modelName: 'GRANDLAND',
      displayName: 'Grandland',
      urlName: 'grandland',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/34475188-9296-4a09-9849-a176008e5301',
      description: 'Meet All-New Grandland.  Book a Test Drive.',
      lastMod: '2023-03-06T20:28:01.8148763',
    },
    {
      id: 21,
      modelName: 'CROSSLAND',
      displayName: 'Crossland',
      urlName: 'crossland',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/492d1cef-e1e9-44e2-b213-37f2ec8a12a3',
      description: 'Up to £1,500 towards your deposit',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 17,
      modelName: 'ALL-NEW ASTRA ',
      displayName: 'New Astra ',
      urlName: 'astra',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/09e19ca7-f27b-470a-b2e6-1910e67371c2',
      description: 'All-New Astra. Choose from 3 fuel types: Petrol, Diesel or Plug-In Hybrid.',
      lastMod: '2023-04-06T20:39:39.9216585',
    },
    {
      id: 34,
      modelName: 'ALL-NEW ASTRA SPORTS TOURER',
      displayName: 'New Astra Sports Tourer',
      urlName: 'astra-sports-tourer',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/03f312ca-1784-455c-9a96-d655cdef5e00',
      description: 'All-New Astra Sports Tourer including Hybrid -e available for order now. ',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 22,
      modelName: 'e-COMBO',
      displayName: 'Combo Life Electric',
      urlName: 'combo-e-life',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/f2600767-f9be-4532-b520-4dd7a945a903',
      description: 'Plug & Go with Vauxhall. 8 Years Battery Warranty and Roadside Assistance',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 31,
      modelName: 'VIVARO-E',
      displayName: 'Vivaro Life Electric',
      urlName: 'vivaro-life-electric',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/c2a7a545-25c9-4cb5-9234-1423cb218cc4',
      description: 'Plug & Go with Vauxhall. 8 Years Battery Warranty and Roadside Assistance',
      lastMod: '2023-01-16T14:16:32.56',
    },
    // {
    //   id: 0,
    //   modelName: 'new-corsa',
    //   displayName: 'New Corsa 2023',
    //   urlName: 'new-corsa',
    //   photoUrl: '',
    //   description: '',
    //   lastMod: '',
    // },
    // {
    //   id: 0,
    //   modelName: 'corsa-hybrid',
    //   displayName: 'New Corsa Hybrid',
    //   urlName: 'corsa-hybrid',
    //   photoUrl: '',
    //   description: '',
    //   lastMod: '',
    // },
    // {
    //   id: 0,
    //   modelName: 'grandland-hybrid',
    //   displayName: 'New Grandland Hybrid',
    //   urlName: 'grandland-hybrid',
    //   photoUrl: '',
    //   description: '',
    //   lastMod: '',
    // },
    // {
    //   id: 0,
    //   modelName: 'yes-corsa',
    //   displayName: 'New Corsa Yes Edition',
    //   urlName: 'yes-corsa',
    //   photoUrl: '',
    //   description: '',
    //   lastMod: '',
    // },
  ]

  let vans = [
    {
      id: 18,
      modelName: 'COMBOCARGO',
      displayName: 'Combo ',
      urlName: 'combo-cargo',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/1a212fd9-cba6-4d56-9789-ef77247f1880',
      description: 'The multi award-winning Combo Van',
    },
    {
      id: 23,
      modelName: 'e-COMBOCARGO',
      displayName: 'Combo Electric',
      urlName: 'e-combo-cargo',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/64e47264-f376-44e0-8ffc-7caf9a5a7944',
      description: 'from £29,500 (excl. VAT & inclusive £2,500 PIVG)',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 33,
      modelName: 'VIVARO',
      displayName: 'Vivaro',
      urlName: 'vivaro',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/266a1ea9-2fe5-4c09-8712-a2ca6d25318f',
      description: 'Class-leading fuel economy',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 32,
      modelName: 'VIVARO-e',
      displayName: 'Vivaro Electric',
      urlName: 'vivaro-electric',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/4ee8be5e-b758-476e-9192-d3e2088c2c1f',
      description: 'from £32,250 (excl. VAT & inclusive £5,000 PIVG)',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 29,
      modelName: 'MOVANO',
      displayName: 'New Movano',
      urlName: 'movano',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/ea947f97-0e18-4dc2-9fe8-8054f6eb74dc',
      description: 'Top build quality and loads of practicality',
      lastMod: '2023-01-16T14:16:32.56',
    },
    {
      id: 30,
      modelName: 'MOVANO-e',
      displayName: 'New Movano Electric',
      urlName: 'movano-electric',
      photoUrl: 'https://advancemotorswcstor.blob.core.windows.net/photo-storage/bf9232da-1826-432b-b3b7-effdf01bbba7',
      description: 'from £57,750 (excl. VAT & inclusive £5,000 PIVG)',
      lastMod: '2023-01-16T14:16:32.56',
    },
  ]

  let data = varClassId == '1' ? cars : vans

  return (
    <>
      {data.map((e: any) => {
        return (
          <li>
            <Link
              to={{
                pathname: `${varClassId == '1' ? (e.id !== 0 ? '/new-cars-offers' : '') : '/new-vans-offers'}/${
                  e.urlName
                }`,
              }}
            >
              {e.displayName}
            </Link>
          </li>
        )
      })}
    </>
  )
}

export default App
