import { FC } from 'react'
import { Link } from 'react-router-dom'

const NewArrivals: FC = () => {
  return (
    <>
      <li>
        <Link
          to={{
            pathname: '/mokka-griffin',
          }}
        >
          New Mokka Griffin
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/yes-corsa',
          }}
        >
          New Corsa Yes Edition
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/corsa-hybrid',
          }}
        >
          New Corsa Hybrid
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/new-corsa',
          }}
        >
          New Corsa
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/grandland-hybrid',
          }}
        >
          New Grandland Hybrid
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: '/astra-griffin',
          }}
        >
          New Astra Griffin
        </Link>
      </li>
      {/* <li>
        <Link
          to={{
            pathname: 'new-frontera',
          }}
        >
          New Frontera
        </Link>
      </li> */}
    </>
  )
}

export default NewArrivals
